import { useTheme } from 'context';
import ReactJson, { ReactJsonViewProps } from 'react-json-view';

const env = (process.env.REACT_APP_ENV ?? 'dev').toLowerCase();

export default function Debug({ hidden, ...props }: ReactJsonViewProps & { hidden?: boolean }) {
  const { darkMode } = useTheme();
  return hidden || env === 'prod' ? (
    <></>
  ) : (
    <ReactJson collapsed theme={darkMode ? 'bright' : 'bright:inverted'} {...props} />
  );
}
